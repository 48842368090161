<template>
  <div class="layout-main login-page flex-column justify-content-start align-items-center">
    <div class="login-card d-flex flex-column align-items-center">
      <button class="btn common-btn dark" @click="handleLoginClick">Line 登入 / 註冊</button>
<!--      <h2>Login</h2>-->
<!--      <a :href="`https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${lineClientId}&redirect_uri=${callbackUri}&disable_ios_auto_login=true&bot_prompt=aggressive&scope=profile%20openid%20email&state=linelogin`">點這裡登入</a>-->
    </div>
    <a class="login-link d-flex align-items-center fs-body-16" href="https://lin.ee/0kSLswL" id="nav-link-mobile-line-official">
      <Icon name="line" size="24" />
      <span>Line @揪山登山路線查詢小幫手</span>
    </a>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: 'Login',
  beforeRouteLeave(to, from, next) {
    this.$store.commit('clearRedirectRoute');
    next();
  },
  data() {
    return {
      
    };
  },
  props: {
  },
  components: {
  },
  computed: {
    ...mapState(['lineClientId']),
    ...mapGetters(['hasToken']),
    callbackUri() {
      return `${window.location.origin}/login`;
      // return window.location.origin + window.location.pathname;
    },
	},
  watch: {
    
  },
  async mounted() {
    if (this.hasToken) {
      this.$router.push('/');
    } else {
      const code = this.$route.query.code;
      const state = this.$route.query.state;

      if (code && state === 'linelogin') {
        this.loginByLineCode(code);
      }
    }
	},
  methods: {
    ...mapActions(['appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),
    async loginByLineCode(code) {
      this.appendComponentBusy('LINELOGIN');
      try {
        const callbackUri = this.callbackUri;
        const redirect = await this.$store.dispatch('getRedirectRoute');
        const {token} = await this.$store.dispatch('api/postLineLoginPromise', {code, callbackUri});
        // console.log(token);
        await this.$store.dispatch('login', token);

        // 登錄成功後，將用戶重定向到原本需要驗證的頁面
        console.log('redirect:' + redirect)
        if (redirect) {
          this.$router.push(redirect);
        } else {
          this.$router.push('/');
        }
      } catch(e) {
        console.error(e);
        this.clearQuery();
        this.$store.dispatch('logout');
      } finally {
        this.clearComponentBusy('LINELOGIN');
      }
    },
    clearQuery() {
      window.history.pushState({}, null, window.location.pathname);
    },
    handleLoginClick() {
      const lineClientId = this.lineClientId;
      const callbackUri = this.callbackUri;
      const url = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${lineClientId}&redirect_uri=${callbackUri}&disable_ios_auto_login=true&bot_prompt=aggressive&scope=profile%20openid%20email&state=linelogin`;

      window.location.href = url;
    },
  }
}
</script>


<style lang="scss" scoped>
@import "src/assets/scss/basic";
.login-page {
  background: $color-white;
  .login-card {
    padding-top: 6rem;
    padding-bottom: 8rem;
  }
  hr {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .login-link svg {
    margin-right: .5rem;
  }
}
</style>
